<template>
  <el-dialog
    class="rechargeDialog"
    title="续期"
    :visible.sync="visible"
    width="530px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"  
    :before-close="hideDialog">
    <div class="flex flex-center renew-time">
      <img src="../../../assets/icons/info-circle-filled.png" style="width:16px;height:16px;margin-right:4px" alt="">
      <span>最新续费到期时间：{{info.lastRenewTime}}</span>
    </div>
    <div :style="{'padding':'0 20px'}">
      <div class="form-item flex flex-center">
        <span class="form-label">
          <span class="must">*</span>
          续期方式
        </span>
        <el-radio-group
          class="flex-1"
          v-model="form.rechargeType"
        >
          <el-radio
            v-for="item in typeOption"
            :key="item.k"
            :label="item.k">{{item.v}}</el-radio>
        </el-radio-group>
      </div>
      <div class="form-item flex flex-center">
        <span class="form-label">
          <span class="must">*</span>
          到期时间
        </span>
        <el-date-picker
          class="flex-1"
          size="small"
          v-model="form.expireTime"
          type="date"
          :picker-options="pickerOptions"
          value-format="yyyy-MM-dd"
          placeholder="请选择到期时间"
          :disabled="form.rechargeType == 2"
          popper-class="date-picker-class"
        >
        </el-date-picker>
      </div>
      <div class="form-item flex flex-center" v-show="form.rechargeType == 1">
        <span class="form-label">
          <span class="must">*</span>
          支付渠道
        </span>
        <el-select class="flex-1" v-model="form.payMethod" placeholder="请选择支付渠道" size="small">
          <el-option
            v-for="item in payMethodOption"
            :key="item.k"
            :label="item.v"
            :value="item.k">
          </el-option>
        </el-select>
      </div>
      <div class="form-item flex flex-center" v-show="form.rechargeType == 1">
        <span class="form-label">
          <span class="must">*</span>
          续费金额(元)
        </span>
        <el-input class="flex-1" v-model="form.amount" size="small"></el-input>
      </div>
      <div class="form-item flex flex-center" v-show="form.rechargeType == 2">
        <span class="form-label">
          <span class="must">*</span>
          到期延迟(天)
        </span>
        <el-input class="flex-1" v-model="form.delayTime" size="small"></el-input>
      </div>
      <div class="form-item flex flex-center" v-show="form.rechargeType == 1">
        <span class="form-label self-start">
          <span class="must">*</span>
          上传凭证
        </span>
        <div class="flex-1">
          <upload v-if="visible" :limit="4" @loading="loading = true" @change="handleImageChange"></upload>
        </div>
      </div>
      <div class="form-item flex flex-center">
        <span class="form-label self-start">
          <span class="must">*</span>
          备注原因
        </span>
        <el-input type="textarea" class="flex-1" v-model="form.remark" size="small" maxlength="255" rows="5" show-word-limit placeholder="请输入备注原因"></el-input>
      </div>
    </div>
    <div slot="footer">
      <el-button size="small" @click="hideDialog">取消</el-button>
      <el-button type="primary" size="small" @click="confirm" :disabled="loading">
        保存
        <i class="el-icon-loading" v-show="loading"></i>
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import moment from 'moment'
import { renew_hotel, delay_hotel } from '@/api/hotel'
import { get_key_value } from '@/api/common'
import { FormValid } from "@/utils/formValid";
import upload from '@/components/upload'

const form = {
  hotelId: '',
  rechargeType: '1',
  expireTime: '',
  payMethod: '',
  amount: '',
  delayTime: '',
  remark: '',
  imgUrlList: []
}
export default {
  components: {
    upload
  },
  props: {
    visible: {
      type: Boolean
    },
    info: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      form: this.$deepClone(form),
      expireTime: '',
      payMethodOption: [],
      typeOption: [],
      loading: false
    }
  },
  watch: {
    visible(val) {
      if (val) {
        if (this.info.id) {
          this.form.hotelId = this.info.id
          this.form.expireTime = this.info.expireTime
          this.expireTime = this.info.expireTime
        }
      } else {
        this.form = this.$deepClone(form)
        this.loading = false
      }
    }
  },
  computed: {
    pickerOptions() {
      return {
        disabledDate: date => {
          return moment(date).isBefore(moment(), 'day')
        },
        cellClassName: date => {
          if (this.expireTime == moment(date).format('YYYY-MM-DD'))
            return 'date-cell-highlight'
          else return ''
        }
      }
    },
    checkFormList() {
      let arr = [
        {
          label: '到期时间',
          value: 'expireTime',
          methods: ['notEmpty']
        }
      ]
      if (this.form.rechargeType == 1) {
        arr = arr.concat([
          {
            label: '支付渠道',
            value: 'payMethod',
            methods: ['notEmpty']
          },
          {
            label: '续费金额',
            value: 'amount',
            methods: ['notEmpty', 'isInt']
          },
          {
            label: '凭证',
            value: 'imgUrlList',
            methods: ['notEmpty']
          }
        ])
      } else if (this.form.rechargeType == 2) {
        arr = arr.concat([
          {
            label: '到期延迟',
            value: 'delayTime',
            methods: ['notEmpty', 'isInt']
          }
        ])
      }
      arr = arr.concat([
        {
          label: '备注原因',
          value: 'remark',
          methods: ['notEmpty']
        }
      ])
      return arr
    }
  },
  mounted() {
    this.getOption()
  },
  methods: {
    getOption() {
      get_key_value({
        type: 'HOTEL_RENEW_PAY_METHOD'
      }).then(res => {
        this.payMethodOption = res ? res.records : []
      })
      get_key_value({
        type: 'HOTEL_RENEW_TYPE'
      }).then(res => {
        this.typeOption = res ? res.records : []
        this.typeMap = this.$arrayToMap(this.typeOption)
      })
    },
    handleImageChange(list) {
      this.loading = false
      this.form.imgUrlList = list.concat()
    },
    hideDialog() {
      this.$emit('close')
    },
    checkForm() {
      const formValid = new FormValid();
      for (const f of this.checkFormList) {
        const { label, value, methods } = f;
        const v = this.form[value]
        formValid.add(label, v, methods)
      }
      const res = formValid.start();
      if (res !== true) {
        this.$message.warning(res)
        return false;
      }
      return {
        ...this.form
      };
    },
    confirm() {
      let params = this.checkForm()
      if (!params) return
      this.loading = true
      if (params.rechargeType == 1) {
        delete params.rechargeType
        delete params.delayTime
        renew_hotel(params).then(() => {
          this.loading = false
          this.$message.success('续期成功')
          this.$emit('confirm')
        }).catch(() => {
          this.loading = false
        })
      } else if (params.rechargeType == 2) {
        delete params.rechargeType
        delete params.expireTime
        delete params.payMethod
        delete params.amount
        delay_hotel(params).then(() => {
          this.loading = false
          this.$message.success('延期成功')
          this.$emit('confirm')
        }).catch(() => {
          this.loading = false
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form-item{
  padding-left: 7px;
  margin-bottom: 16px;
  .must{
    color: #E14B38
  }
  .form-label{
    width: 100px;
    text-align: right;
    padding-right: 20px;
  }
}

.tree-select{
  height: 30px;
  line-height: 30px;
  background-color: #FFFFFF;
  border-radius: 4px;
  border: 1px solid #DCDFE6;
  color: #606266;
  padding: 0 10px 0 15px;
  font-size: 13px;
}
.rechargeDialog{
  ::v-deep{
    .el-dialog__body{
      padding: 0 0 30px 0
    }
  }
  .renew-time{
    color: #212121;
    background: #F0F8FF;
    font-size: 14px;
    padding: 8px 0;
    margin-bottom: 16px;
  }
}
</style>
<style lang="scss">
.date-picker-class .el-date-table .date-cell-highlight{
  span {
    color: red !important;
  }
}
</style>